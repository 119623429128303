exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */),
  "component---src-pages-works-online-study-room-tsx": () => import("./../../../src/pages/works/online-study-room.tsx" /* webpackChunkName: "component---src-pages-works-online-study-room-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

